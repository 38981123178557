.displayPageLikeMenu {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.displaySingleOption {
    height: 450px;
    width: 30%;
    border-style: solid;
    border-color: #38015c;
    border-width: 5px;
    border-radius: 30px;
    text-align: center;
    padding-top: 10%;
    padding-bottom: 10%;
    margin: 3%;
}

