.myfooter {
    background-color: #e2c795;
    padding: 15px;
}

.footer {
    width: 100%;
    height: 5rem;
    background-color: #38015c;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
  }
  
  .logo {
    font-family: 'Lato', sans-serif;
    font-size: 2rem;
    color: white;
    margin: 0;
  }
  
  .footer h5 {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
    text-decoration: none;
    color: white;
    font-weight: bold;
  }
  